import React, { useEffect } from 'react'
import { getRoutesByLang, routeNames } from '../../utils/helpers/routes'
import AnimatedCard from '../AnimatedCard'
import { animateServices } from '../../utils/page-animations/common'
import { useTranslation } from 'react-i18next'

const ServiceBlocks = ({ language, location, animation }) => {
  const localizedRoutes = getRoutesByLang(language)
  const { t } = useTranslation()

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateServices()
    }
  }, [animation])

  return (
    <>
      <div className="row mb-0 mb-md-8">
        <div className="col col-lg-4 col-xl-3 col-lg-offset-1 col-xl-offset-2 mb-3 mb-lg-0">
          <p className="ft-default mb-0 tpl-service__services__title">
            {t('service:our_services:title')}
          </p>
        </div>
        <div className="col col-lg-8 col-xl-5">
          <h6 className="ft-400 tpl-service__services__title">
            {t('service:our_services:desc')}
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-12 col-lg-offset-1 col-xl-7 col-xl-offset-5">
          <div className="mosaic mosaic-lg-3 mosaic-xl-2 mosaic--service-blocks">
            {location !== 'strategie' && location !== 'strategy' && (
              <AnimatedCard
                item="item_1"
                url={localizedRoutes[routeNames.strategy]}
                svg="doodle"
                className="card-animated--strategy card-animated--small"
                duration={20}
              />
            )}
            {location !== 'produit' && location !== 'product' && (
              <AnimatedCard
                item="item_2"
                url={localizedRoutes[routeNames.product]}
                svg="dashed-arrow"
                className="card-animated--product card-animated--small"
                duration={50}
              />
            )}
            {location !== 'design-at-scale' && (
              <AnimatedCard
                item="item_3"
                url={localizedRoutes[routeNames.atScale]}
                svg="circle"
                className="card-animated--design card-animated--small"
                duration={20}
              />
            )}
            {location !== 'tech' && (
              <AnimatedCard
                item="item_4"
                url={localizedRoutes[routeNames.tech]}
                svg="engineering"
                className="card-animated--engineering card-animated--small"
                duration={20}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceBlocks
