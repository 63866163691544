import React, { useEffect } from 'react'
import { animateCapabilities } from '../../utils/page-animations/services'

const ListCapabilities = ({ animation, capabilities }) => {
  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateCapabilities()
    }
  }, [animation])

  return (
    <ul className="mosaic mosaic--2 mosaic--gap-20 mosaic-lg-2">
      <li>
        <p className="ft-default-m-small mb-2">{capabilities.col1.title}</p>
        <ul>
          {capabilities.col1.items.map((capability) => (
            <li className="ft-default-m-small mb-1 c-gray-600" key={capability}>
              {capability}
            </li>
          ))}
        </ul>
      </li>
      <li>
        <p className="ft-default-m-small mb-2" style={{ minHeight: 30 }}>
          {capabilities.col2.title}
        </p>
        <ul>
          {capabilities.col2.items.map((capability) => (
            <li className="ft-default-m-small mb-1 c-gray-600" key={capability}>
              {capability}
            </li>
          ))}
        </ul>
      </li>
    </ul>
  )
}

export default ListCapabilities
