import React, { useCallback, useEffect, useRef, useState } from 'react'
import { iconMinus, iconPlus } from '../../utils/icons'
import gsap from 'gsap'
import { ease1 } from '../../utils/constants/eases'

const Accordion = ({ children, title, search, index }) => {
  const [accordion, setAccordion] = useState(false)
  const accordionBodyRef = useRef(null)

  const animateAccordion = useCallback(() => {
    gsap.to(accordionBodyRef.current, {
      height: accordion ? 'auto' : 0,
      opacity: accordion ? 1 : 0,
      duration: 0.6,
      ease: ease1,
    })
  }, [accordion])

  useEffect(() => {
    animateAccordion()
  }, [accordion, animateAccordion])

  const accordionHandler = () => {
    setAccordion(!accordion)
  }

  useEffect(() => {
    if (search) {
      let params = new URLSearchParams(search)
      const accordionIndex = params.get('accordionIndex')

      if (Number(accordionIndex) === index) {
        setAccordion(true)
      }
    }
  }, [search, index])

  return (
    <div className={`accordion ${accordion ? 'is-open' : ''}`}>
      <div
        className="accordion__trigger f f-jc-space-b"
        onClick={accordionHandler}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            accordionHandler()
          }
        }}
      >
        <p className={`h6 mb-0 ${!accordion && 'c-gray-300'}`}>{title}</p>
        <div className="accordion__icon">
          {accordion ? iconMinus : iconPlus}
        </div>
      </div>
      <div className="accordion__body" ref={accordionBodyRef}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
