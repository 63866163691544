import gsap from 'gsap'
import { easeOutQuad } from '../../utils/constants/eases'
import SplitType from 'split-type'
import {
  gsapParams,
  setAdvantagesListEntry,
  setClientsEntry,
  setContactEntry,
  setServicesEntry,
} from './common'

const selectors = {
  title: '.hero__title',
  subtitle: '.hero__subtitle',
  titleLines: '.tpl-services__title-line',
  accordions: {
    wrapper: '.tpl-service-accordions',
    items: '.accordion',
  },
  capabilities: {
    wrapper: '.tpl-service-capabilities',
    title: '.tpl-service-capabilities__title',
    items: '.tpl-service-capabilities li',
  },
  scale: {
    wrapper: '.tpl-service-scale',
    title: '.tpl-service-scale__title',
    img: '.tpl-service-scale img',
  },
  useCasesTitle: {
    wrapper: '.use-cases__title',
    content: '.use-cases__title',
  },
  useCases: {
    wrapper: '.use-cases',
    content: '.use-cases__card',
  },
}

const setTitleEntry = (show) => {
  const title = new SplitType(selectors.title, {
    wordClass: 'tpl-services__title-line',
    types: 'words',
  })
  gsap.set(
    title.words,
    show ? { ...gsapParams.showInUp } : { ...gsapParams.fadeOutDown50 }
  )
  gsap.set(
    selectors.subtitle,
    show ? { ...gsapParams.showInUp } : { ...gsapParams.fadeOutDown50 }
  )
}

const setAccordions = (show) => {
  const accordionComponent = document.querySelector(selectors.accordions.items)

  if (accordionComponent) {
    gsap.set(
      selectors.accordions.items,
      show ? gsapParams.showInUp : gsapParams.fadeOutDown50
    )
  }
}

const setCapabilities = (show) => {
  gsap.set(
    selectors.capabilities.title,
    show ? gsapParams.showInUp : gsapParams.fadeOutDown
  )
  gsap.set(
    selectors.capabilities.items,
    show ? gsapParams.showInUp : gsapParams.fadeOutDown
  )
}

const setUseCases = (show) => {
  gsap.set(selectors.useCases.wrapper, { scaleX: show ? 1 : 0 })
  gsap.set(
    selectors.useCases.content,
    show ? { ...gsapParams.showInUp } : { ...gsapParams.fadeOut }
  )
}

const setUseCasesTitle = (show) => {
  gsap.set(
    selectors.useCasesTitle.content,
    show ? gsapParams.showInUp : gsapParams.fadeOutDown
  )
}

const setDesignAtScale = (show) => {
  const scalePage = document.querySelector(selectors.scale.wrapper)
  if (scalePage) {
    gsap.set(
      selectors.scale.title,
      show ? gsapParams.showInUp : gsapParams.fadeOutDown
    )
    gsap.set(
      selectors.scale.img,
      show ? gsapParams.showInUp : gsapParams.fadeOutDown
    )
  }
}

const animateTitleEntry = () => {
  const tl = gsap.timeline()
  tl.to(
    selectors.titleLines,
    {
      ...gsapParams.fadeInUp,
      stagger: {
        each: 0.05,
        grid: 'auto',
        from: 'start',
        ease: easeOutQuad,
        axis: 'y',
      },
    },
    0
  )
  tl.to(
    selectors.subtitle,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )
  return tl
}

const animateAccordions = () => {
  const accordionComponent = document.querySelector(selectors.accordions.items)

  if (accordionComponent) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: selectors.accordions.wrapper,
        start: '10% 90%',
        end: 'bottom center',
        once: true,
      },
    })
    tl.to(
      selectors.accordions.items,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        ease: easeOutQuad,
        stagger: {
          each: 0.025,
          grid: [2, 1],
          from: 'start',
          axis: 'x',
        },
      },
      0
    )

    return tl
  }
}

const animateCapabilities = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.capabilities.wrapper,
      start: '10% 90%',
      end: 'bottom center',
      once: true,
    },
  })
  tl.to(
    selectors.capabilities.title,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )
  tl.to(
    selectors.capabilities.items,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
      stagger: {
        each: 0.025,
        grid: [2, 1],
        from: 'start',
        axis: 'x',
      },
    },
    0
  )

  return tl
}

const animateDesignAtScale = () => {
  const scalePage = document.querySelector(selectors.scale.wrapper)
  if (scalePage) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: selectors.scale.wrapper,
        start: '10% 90%',
        end: 'bottom center',
        once: true,
      },
    })
    tl.to(
      selectors.scale.title,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        ease: easeOutQuad,
      },
      0
    )
    tl.to(
      selectors.scale.img,
      {
        opacity: 1,
        translateY: 0,
        duration: 0.6,
        ease: easeOutQuad,
      },
      0
    )

    return tl
  }
}

const animateUseCasesContent = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.useCases.wrapper,
      start: '10% 90%',
      end: 'bottom center',
      once: true,
    },
  })
  tl.to(
    selectors.useCases.wrapper,
    {
      scaleX: 1,
      duration: 0.6,
      ease: easeOutQuad,
    },
    0
  )
  tl.to(
    selectors.useCases.content,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
      stagger: {
        each: 0.025,
        grid: [2, 1],
        from: 'start',
        axis: 'x',
      },
    },
    0.5
  )
  return tl
}

const animateUseCasesTitle = ({ onComplete }) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.useCasesTitle.wrapper,
      start: '10% 90%',
      end: 'bottom center',
      once: true,
    },
  })
  tl.to(
    selectors.useCasesTitle.content,
    {
      opacity: 1,
      translateY: 0,
      duration: 0.6,
      ease: easeOutQuad,
      onComplete,
    },
    0
  )

  return tl
}

const animateEntry = () => {
  const tl = gsap.timeline()
  tl.add(animateTitleEntry(), 0)
  tl.add(animateAccordions(), 0)

  return { tl }
}

const setEntry = () => {
  setTitleEntry()
  setContactEntry()
  setAccordions()
  setCapabilities()
  setClientsEntry()
  setDesignAtScale()
  setAdvantagesListEntry()
  setServicesEntry()
  setUseCases()
  setUseCasesTitle()
}

const setVisibleEntry = () => {
  setTitleEntry(true)
  setContactEntry(true)
  setAccordions(true)
  setCapabilities(true)
  setClientsEntry(true)
  setDesignAtScale(true)
  setAdvantagesListEntry(true)
  setServicesEntry(true)
  setUseCases(true)
  setUseCasesTitle(true)
}

export {
  animateEntry,
  setEntry,
  setVisibleEntry,
  animateUseCasesContent,
  animateCapabilities,
  animateDesignAtScale,
  animateUseCasesTitle,
}
