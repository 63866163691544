import React, { useMemo, useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import CardService from '../components/Cards/CardService'
import Accordion from '../components/Accordion'
import ListCapabilities from '../components/ListCapabilities'
import ListTheyTrustUs from '../components/ListTheyTrustUs'
import UseCases from '../components/UseCases'
import AdvantagesList from '../components/AdvantagesList'
import ServiceBlocks from '../components/ServiceBlocks'
import Contact from '../components/Contact'
import {
  animateEntry,
  setEntry,
  setVisibleEntry,
} from '../utils/page-animations/services'
import useAnimationState from '../utils/hooks/useAnimationState'
import ScrollTrigger from 'gsap/ScrollTrigger'
import DesignAtScale from '../components/DesignAtScale/DesignAtScale'
import ClassicLayout from '../components/Layouts/ClassicLayout'

const Service = (props) => {
  const { data, pageContext, location } = props
  const { routeName, language } = pageContext
  const { t } = useTranslation()

  const service = useMemo(() => data.service?.data, [data])
  const serviceTitle = service.title[0].text
  const serviceName = service.name[0].text
  const serviceFeatures = service.body

  const seo = {
    title: t('service:seo:title', { service: serviceName }),
    description: t('service:seo:description'),
  }

  const clients = useMemo(() => {
    switch (pageContext.uid) {
      case 'design-at-scale':
        return ['bnpp', 'l-oreal', 'dassault', 'abu-dhabi', 'mediaspot']
      case 'tech':
        return ['jmm', 'l-oreal', 'zenly-svg', 'pathe', 'asmodee', 'hennessy']
      case 'product':
      case 'produit':
        return [
          'bnpp',
          'ledger',
          'bpce',
          'socgen',
          'coin-shares',
          'pathe',
          'dassault',
          'asmodee',
          'wb',
          'mediaspot',
          'yves-rocher',
          'l-oreal',
          'aroma-zone',
          'frichti',
          'hennessy',
          'bnpp',
        ]
      default:
        return [
          'l-oreal',
          'coutot',
          'smeg',
          'sunday',
          'frichti',
          'getfluence',
          'smart-tribune',
          'bpce',
          'provalliance',
          'l-oreal',
        ]
    }
  }, [pageContext.uid])

  const capabilities = useMemo(() => {
    switch (pageContext.uid) {
      case 'design-at-scale':
        return {
          col1: {
            title: t('service:capabilities:design:title'),
            items: [
              t('service:capabilities:design:dls'),
              t('service:capabilities:design:atomic_user_research'),
              t('service:capabilities:design:organization'),
              t('service:capabilities:design:maturity_evaluation'),
              t('service:capabilities:design:adoption_strategy'),
              t('service:capabilities:design:design_ops'),
            ],
          },
          col2: {
            title: t('service:capabilities:tech:title'),
            items: [
              t('service:capabilities:tech:system_architecture'),
              t('service:capabilities:tech:dev_ops'),
              t('service:capabilities:tech:optimization'),
              t('service:capabilities:tech:technical_requirements'),
            ],
          },
        }
      case 'tech':
        return {
          col1: {
            title: t('service:capabilities:tech:title'),
            items: [
              t('service:capabilities:tech:feasability_assessment'),
              t('service:capabilities:tech:system_architecture'),
              t('service:capabilities:tech:technical_requirements'),
              t('service:capabilities:tech:cost_estimate'),
              t('service:capabilities:tech:proof_of_concept_development'),
            ],
          },
          col2: {
            title: '',
            items: [
              t('service:capabilities:tech:technical_audit'),
              t('service:capabilities:tech:frontend_dev'),
              t('service:capabilities:tech:backend_dev'),
              t('service:capabilities:tech:mobile_dev'),
            ],
          },
        }
      case 'product':
      case 'produit':
        return {
          col1: {
            title: t('service:capabilities:design:title'),
            items: [
              t('service:capabilities:design:ux_design'),
              t('service:capabilities:design:visual_design'),
              t('service:capabilities:design:brand_design'),
              t('service:capabilities:design:interactive_prototyping'),
              t('service:capabilities:design:design_ops'),
            ],
          },
          col2: {
            title: t('service:capabilities:tech:title'),
            items: [
              t('service:capabilities:tech:frontend_dev'),
              t('service:capabilities:tech:mobile_dev'),
              t('service:capabilities:tech:backend_dev'),
              t('service:capabilities:tech:tech_lead'),
              t('service:capabilities:tech:dev_ops'),
            ],
          },
        }
      default:
        return {
          col1: {
            title: t('service:capabilities:design:title'),
            items: [
              t('service:capabilities:design:user_research'),
              t('service:capabilities:design:concept_ideation'),
              t('service:capabilities:design:ui_ux_design'),
              t('service:capabilities:design:interactive_prototyping'),
              t('service:capabilities:design:product_roadmap'),
              t('service:capabilities:design:brand_design'),
            ],
          },
          col2: {
            title: t('service:capabilities:tech:title'),
            items: [
              t('service:capabilities:tech:feasability_assessment'),
              t('service:capabilities:tech:system_architecture'),
              t('service:capabilities:tech:technical_requirements'),
              t('service:capabilities:tech:cost_estimate'),
              t('service:capabilities:tech:proof_of_concept_development'),
            ],
          },
        }
    }
  }, [pageContext.uid, t])

  // ANIMATION
  const animationState = useAnimationState({ routeName })
  const [childrenAnimation, setChildrenAnimation] = useState({
    isReady: false,
    canPlay: false,
    canStart: false,
  })

  // REF
  const $hasRunAnimation = useRef(false)

  //HOOKS
  useEffect(() => {
    ScrollTrigger.clearScrollMemory()
    setEntry()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setChildrenAnimation((prevState) => ({
        ...prevState,
        isReady: true,
        canStart: false,
      }))
      setVisibleEntry()
      return
    }
    setChildrenAnimation((prevState) => ({
      ...prevState,
      isReady: true,
      canStart: true,
    }))
  }, [animationState.isReady, animationState.canStart])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }
    const tls = animateEntry()
    $hasRunAnimation.current = true

    return () => {
      tls.tl && tls.tl.kill()
      tls.scrollTl && tls.scrollTl.kill()
    }
  }, [animationState.canPlay])

  useEffect(() => {
    if (location.search) {
      let params = new URLSearchParams(location.search)
      const accordionIndex = params.get('accordionIndex')

      const currentAccordion =
        document.querySelectorAll('.accordion')[Number(accordionIndex)]

      currentAccordion?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      })
    }
  }, [location.search])

  return (
    <ClassicLayout
      name={routeName}
      uri={props.uri}
      language={language}
      seo={seo}
      internationalRoute={pageContext.internationalRoute}
    >
      <section className="hero hero--service">
        <div className="container">
          <div className="row">
            <div className="col col-lg-12 col-xl-10 col-lg-offset-1 col-xl-offset-2">
              <p className="hero__subtitle hide-desktop c-primary mb-2">
                <span>{t(`common:nav:${pageContext.uid}`)}</span>
              </p>
              <h4
                className="hero__title ft-no-select"
                aria-label={serviceTitle}
              >
                {serviceTitle}
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="container tpl-service-accordions">
        <div className="row">
          <div className="col col-lg-12 col-xl-10 col-lg-offset-1 col-xl-offset-2">
            {serviceFeatures.map((feature, key) => (
              <Accordion
                key={key}
                title={feature.primary.feature_title[0].text}
                search={location.search}
                index={key}
              >
                <p
                  className={`ft-default-m-small c-gray-300 c-alpha-6 ${
                    feature.items.length ? 'mb-3 mb-lg-5' : ''
                  } mt-2`}
                >
                  {feature.primary.feature_subtitle[0]?.text}
                </p>
                {feature.items.length > 0 && (
                  <p className="ft-secondary c-alpha-3 mb-1 mb-lg-2">
                    {t('service:showcase_project')}
                  </p>
                )}
                <div className="carousel-card f mb-2 mb-lg-4 hide-scrollbar">
                  {feature.items.map((item, k) => (
                    <CardService
                      key={k}
                      data={item}
                      language={language}
                      accordionIndex={key}
                    />
                  ))}
                </div>
              </Accordion>
            ))}
          </div>
        </div>
      </section>

      {pageContext.uid === 'design-at-scale' && (
        <section className="container tpl-service-scale">
          <DesignAtScale animation={childrenAnimation} />
        </section>
      )}

      <section className="container tpl-service-capabilities">
        <div className="row">
          <div className="col col-lg-4 col-xl-3 col-lg-offset-1 col-xl-offset-2">
            <p className="ft-default mb-4 tpl-service-capabilities__title">
              {t('service:capabilities:title')}
            </p>
          </div>
          <div className="col col-lg-8 col-xl-6">
            <ListCapabilities
              animation={childrenAnimation}
              capabilities={capabilities}
            />
          </div>
        </div>
      </section>

      <ListTheyTrustUs
        language={language}
        clients={clients}
        animation={childrenAnimation}
      />

      <UseCases
        language={language}
        content={data.service.data.case_studies}
        animation={childrenAnimation}
        pageContext={pageContext}
        search={location.search}
      />

      <section className="tpl-service__advantages container">
        <AdvantagesList animation={childrenAnimation} />
      </section>

      <section className="tpl-service__services container">
        <ServiceBlocks
          language={language}
          location={pageContext.uid}
          animation={childrenAnimation}
        />
      </section>

      <section className="container">
        <Contact animation={childrenAnimation} />
      </section>
    </ClassicLayout>
  )
}

export const queryHome = graphql`
  query ($uid: String, $language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    service: prismicService(uid: { eq: $uid }, lang: { eq: $contentLang }) {
      data {
        title {
          text
        }
        name {
          text
        }
        case_studies {
          case_study_title {
            text
          }
          case_study_description {
            text
          }
          case_study_company
          case_study_main_picture {
            url
          }
          case_study_first_picture {
            url
          }
          case_study_second_picture {
            url
          }
          case_study_reversed
          case_study_view_more_link {
            uid
          }
        }
        body {
          slice_type
          primary {
            feature_title {
              text
            }
            feature_subtitle {
              text
            }
          }
          items {
            card_title {
              text
            }
            card_logo
            card_link {
              uid
              url
            }
            card_content {
              text
            }
          }
        }
      }
    }
  }
`

export default Service
