import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { breakpoints } from '../../utils/helpers/utils'
import { animateDesignAtScale } from '../../utils/page-animations/services'

const DesignAtScale = ({ animation }) => {
  const { t } = useTranslation(['home', 'common'])

  useEffect(() => {
    if (animation.isReady && animation.canStart) {
      animateDesignAtScale()
    }
  }, [animation])

  return (
    <div className="row">
      <div className="col col-md-8 col-md-offset-2 col-lg-8 col-lg-offset-3 col-xl-6 col-xl-offset-4 tpl-service-scale__title">
        <h5 className="ft-center ft-400 mb-1 ">
          {t('service:design_at_scale:title')}
        </h5>
        <p className="ft-center ft-default-m-small c-gray-300 ft-300 mb-5">
          {t('service:design_at_scale:desc')}
        </p>
      </div>
      <div className="col col-lg-12 col-xl-10 col-lg-offset-1 col-xl-offset-2 f f-jc-center">
        <picture>
          <source
            media={`(min-width: ${breakpoints.md}px) and (-webkit-min-device-pixel-ratio: 2), (min-width:${breakpoints.md}px) and (min-resolution: 192dpi)`}
            srcSet="/images/design-at-scale@2x.png"
          />
          <source
            media="(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
            srcSet="/images/design-at-scale-mobile@2x.png"
          />
          <source
            media={`(min-width:${breakpoints.md}px)`}
            srcSet="/images/design-at-scale.png"
          />
          <img
            src="/images/design-at-scale-mobile.png"
            alt="Design at scale illustration"
          />
        </picture>
      </div>
    </div>
  )
}

export default DesignAtScale
